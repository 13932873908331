<template>
<div>
  <div style="display:flex;justify-content: space-between;align-items: center;">
    <v-btn small outlined color="primary" @click="openList"><v-icon>mdi-chevron-left</v-icon>Back to list</v-btn>
    <v-btn small outlined color="primary" @click="saveEdit">Save edit</v-btn>
  </div>
  <div style="display:flex;flex-direction: column;margin-top:15px;">
    <div style="display: flex;align-items: center;margin-bottom: 15px;">
      <label style="width: 100px;margin-bottom: 0; margin-right: 15px;" class="label">Name</label>
      <v-text-field style="max-width: 300px;" outlined dense hide-details v-model="selectedRadio.title"></v-text-field>
    </div>
    <div style="display: flex;flex-direction:column;margin-bottom: 15px;">
      <label style="width: 100px;margin-bottom: 15px;" class="label">Description</label>
      <Editor
          api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
          :init="options"
          v-model="selectedRadio.description"></Editor>
    </div>
    <div style="margin-bottom: 15px;">
      <label  style="width: 100px;margin-bottom: 15px;" class="label">Image</label>
      <vue-dropzone
          :id="`upload`"
          :ref="`upload`"
          :options="dropzoneOptions"
          :destroyDropzone="false"
          @vdropzone-mounted="dropzoneMount"
          @vdropzone-sending="customEvent"
          @vdropzone-success="successUploadImage"
          @vdropzone-removed-file="removeImage"
          @vdropzone-max-files-exceeded="maxFileImage"

      />
    </div>
    <div style="display: flex;justify-content: space-between;">

      <div style="display: flex;align-items: center;margin-bottom: 15px;">
        <label style="width: 100px;margin-bottom: 0; margin-right: 15px;" class="label">Stream link</label>
        <v-text-field placeholder="stream link" outlined dense hide-details v-model="selectedRadio.src"></v-text-field>
      </div>
      <div style="display: flex;align-items: center;margin-bottom: 15px;">
        <label style="width: 100px;margin-bottom: 0; margin-right: 15px;" class="label">Website</label>
        <v-text-field placeholder="website" outlined dense hide-details v-model="selectedRadio.website"></v-text-field>
      </div>
    </div>
  </div>
  <PopupSuccess ref="PopupSuccess" title="Success" content="Your radio edited successfully!"/>
</div>
</template>

<script>
import {mapState,mapMutations} from "vuex"
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Compressor from "compressorjs";
import PopupSuccess from "@/components/Popup/PopupSuccess";
export default {
  name: "RadioEdit",
  data(){
    return {
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFiles: 1,
        destroyDropzone: false,
        autoProcessQueue:false,
        thumbnail:(file,dataUrl)=> {
          if(!file.compressed){
            new Compressor(file, {
              quality: 0.6,
              mimeType:'image/webp',
              convertSize:0,
              convertTypes:['image/jpeg','image/png','image/jpg'],
              success:(result) => {
                result.compressed = true;
                this.$refs.upload.removeAllFiles();
                this.$refs.upload.addFile(result);
                this.$refs.upload.processQueue();
              },
              error(e) {
                console.log(e);
              }
            })
          }
        },
      },
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: 'jpg,svg,webp,gif,png',
        images_upload_handler: async  (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
          formData.append("lending_settings_id", this.$store.state.landing.settings_id);
          formData.append("image", _file);
          formData.append("file", _file);
          const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
          success(data);
        },
        plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: 'jpg,svg,webp,gif,png',
          images_upload_handler: async  (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
            formData.append("lending_settings_id", this.$store.state.landing.settings_id);
            formData.append("image", _file);
            formData.append("file", _file);
            const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
            success(data);
          },
          plugins:
              "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height: 200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 250,
        image_caption: true,
        quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

        setup:function(editor){
          editor.ui.registry.addButton('custom_button', {
            text: 'Add Button',
            onAction: function() {
              // Open a Dialog
              editor.windowManager.open({
                title: 'Add custom button',
                body: {
                  type: 'panel',
                  items: [{
                    type: 'input',
                    name: 'button_label',
                    label: 'Button label',
                    flex: true
                  },{
                    type: 'input',
                    name: 'button_href',
                    label: 'Button href',
                    flex: true
                  },{
                    type: 'selectbox',
                    name: 'button_target',
                    label: 'Target',
                    items: [
                      {text: 'None', value: ''},
                      {text: 'New window', value: '_blank'},
                      {text: 'Self', value: '_self'},
                      {text: 'Parent', value: '_parent'}
                    ],
                    flex: true
                  },{
                    type: 'selectbox',
                    name: 'button_rel',
                    label: 'Rel',
                    items: [
                      {text: 'No value', value: ''},
                      {text: 'Alternate', value: 'alternate'},
                      {text: 'Help', value: 'help'},
                      {text: 'Manifest', value: 'manifest'},
                      {text: 'No follow', value: 'nofollow'},
                      {text: 'No opener', value: 'noopener'},
                      {text: 'No referrer', value: 'noreferrer'},
                      {text: 'Opener', value: 'opener'}
                    ],
                    flex: true
                  },{
                    type: 'selectbox',
                    name: 'button_style',
                    label: 'Style',
                    items: [
                      {text: 'Success', value: 'success'},
                      {text: 'Info', value: 'info'},
                      {text: 'Warning', value: 'warning'},
                      {text: 'Error', value: 'error'}
                    ],
                    flex: true
                  }]
                },
                onSubmit: function (api) {

                  var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                  // insert markup
                  editor.insertContent(html);

                  // close the dialog
                  api.close();
                },
                buttons: [
                  {
                    text: 'Close',
                    type: 'cancel',
                    onclick: 'close'
                  },
                  {
                    text: 'Insert',
                    type: 'submit',
                    primary: true,
                    enabled: false
                  }
                ]
              });
            }
          });
        },
        content_css: ["/custom_tinymce.css"],
      },
    }
  },
  components:{
    PopupSuccess,
    vueDropzone:() => import("vue2-dropzone"),
  },
  methods:{
    ...mapMutations('radio',['editStationFromGroup']),
    dropzoneMount(){
      if(this.selectedRadio.imageSrc){
        let mockFile = {
          compressed: true,
          kind: "image",
        };
        this.$refs.upload.manuallyAddFile(mockFile, this.selectedRadio.imageSrc);
        this.$refs.upload.dropzone.emit("thumbnail", mockFile, this.selectedRadio.imageSrc);
        this.$refs.upload.dropzone.emit("complete", mockFile);
        this.$refs.upload.dropzone['previewsContainer'].children[1].children[0].children[0].src = this.selectedRadio.imageSrc;
        this.$refs.upload.dropzone['previewsContainer'].children[1].children[0].style.borderRadius = 0;
        this.$refs.upload.dropzone['previewsContainer'].children[1].children[1].style.background = 'none';
      }
    },
    openList(){
      this.$emit('openList');
    },
    customEvent(_file, xhr,formData){
      xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
      );
      formData.append('lending_settings_id',this.landing.settings_id);
      formData.append('image',_file);
    },
    successUploadImage(file){
      this.selectedRadio.imageSrc = file.xhr.response;
      this.$refs.upload.dropzone[
          "previewsContainer"
          ].children[1].children[0].children[0].src = this.selectedRadio.imageSrc;
      this.$refs.upload.dropzone[
          "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
      this.$refs.upload.dropzone[
          "previewsContainer"
          ].children[1].children[1].style.background = "none";
    },
    removeImage(){
      this.selectedRadio.imageSrc = null;
    },
    maxFileImage(file){
      this.$refs.upload.removeAllFiles();
      this.$refs.upload.addFile(file);
    },
    saveEdit(){
      const payload = {
        groupID:this.selectedGroupID,
        station:this.selectedRadio,
        content:this.content.content
      }
      this.editStationFromGroup(payload);
      this.$refs.PopupSuccess.open();
    },
  },
  props:['content'],
  computed:{
    ...mapState('radio',['selectedRadio','selectedGroupID']),
    ...mapState(['landing']),
  }
}
</script>

<style scoped>

</style>